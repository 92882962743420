<template>
  <div class="maskLayer" v-if="showTaskBackLayer">
    <div class="app-message dialogCon">
      <div class="dcTitle">
        <p class="title">幸福佳苑小区-1号频道</p>
        <p class="close" v-on:click="handleCloseLayer">
          <img src="@/assets/images/message/tankuang_delete.png" />
        </p>
      </div>
      <div class="hlCon">
        <div class="img">
            <img src="https://cdn.jsdelivr.net/gh/cdn003/cdn2@1.0/images/mn/t01c940d30f150d298a.jpg" alt="">
        </div>
        <div class="iconBtn">
            <div class="item item1">
                <div class="img">
                   <i class="el-icon-phone-outline"></i>
                </div>
                <p>开启</p>
            </div>
            <div class="item item2">
                  <div class="img">
                   <i class="el-icon-mic"></i>
                </div>
                <p>通话</p>
            </div>
            <div class="item item3">
                 <div class="img">
                   <i class="el-icon-close"></i>
                </div>
                <p>取消</p>
            </div>
        </div>
      </div>
    </div>
  </div>
</template> 
<script>
export default {
  props: {
    showTaskBackLayer: {
      type: Boolean,
      default: false,
    },
  },
  name: "",
  data() {
    return {
      radio: "1",
    };
  },
  created() {},
  computed: {},
  methods: {
    handleHintInfo() {},
    handleCloseLayer() {
      this.$emit("update:showTaskBackLayer", false);
    },
  },
};
</script>
<style lang='scss'  scoped>
@import "@/scss/talkback/index.scss";
</style>
