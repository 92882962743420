<template>
  <div class="maskLayer" v-if="showTaskListLayer">
    <div class="app-message dialogCon">
      <div class="dcTitle">
        <p class="title">抓拍告警</p>
        <div class="dcRight">
          <p class="touping">
            <img src="@/assets/images/listMessage/button_touping.png" />
          </p>
          <p class="close" v-on:click="handleCloseLayer">
            <img src="@/assets/images/listMessage/button_close.png" />
          </p>
        </div>
      </div>
      <div class="hlCon">
        <div class="photo-info">
          <div class="leftCon">
            <div class="topCon">
              <img src="https://cdn.jsdelivr.net/gh/cdn003/cdn2@1.0/images/mn/t01c940d30f150d298a.jpg" alt="">
            </div>
            <div class="bottomCon">
              <div class="userPic" >
                <!-- 请求有图片就覆盖下面的图片 -->
                <div class="img">
                 <img
                  src="https://cdn.jsdelivr.net/gh/cdn003/cdn2@1.0/images/mn/t01c940d30f150d298a.jpg"
                  alt=""
                />
               </div>
              </div>
              <div class="comparePic">
                <!-- 默认图片写死的 -->
                <div class="img">
                 <img
                  src="@/assets/images/listMessage/zhuapai_no picture.png"
                  alt=""
                />
               </div>
                <div class="img">
                 <img
                  src="@/assets/images/listMessage/zhuapai_no picture.png"
                  alt=""
                />
               </div>
                <div class="img">
                 <img
                  src="@/assets/images/listMessage/zhuapai_no picture.png"
                  alt=""
                />
               </div>
                <div class="img">
                 <img
                  src="@/assets/images/listMessage/zhuapai_no picture.png"
                  alt=""
                />
               </div>
                <div class="img">
                 <img
                  src="@/assets/images/listMessage/zhuapai_no picture.png"
                  alt=""
                />
               </div>
                <div class="img">
                 <img
                  src="@/assets/images/listMessage/zhuapai_no picture.png"
                  alt=""
                />
               </div>
                <div class="img">
                 <img
                  src="@/assets/images/listMessage/zhuapai_no picture.png"
                  alt=""
                />
               </div>
                <div class="img">
                 <img
                  src="@/assets/images/listMessage/zhuapai_no picture.png"
                  alt=""
                />
               </div>
                <div class="img">
                 <img
                  src="@/assets/images/listMessage/zhuapai_no picture.png"
                  alt=""
                />
               </div>
                <div class="img">
                 <img
                  src="@/assets/images/listMessage/zhuapai_no picture.png"
                  alt=""
                />
               </div>
              </div>
            </div>
          </div>
          <div class="rightCon">
            <div class="topCon">
              <div class="userPic">
                <img
                  src="@/assets/images/listMessage/zhuapai_no picture.png"
                  alt=""
                />
                <img
                  src="@/assets/images/listMessage/zhuapai_no picture.png"
                  alt=""
                />
              </div>
            </div>
            <div class="bottomCon">
              <div class="item">
                <div class="left-lable">姓名：</div>
                <div class="right-lable">张三</div>
              </div>
              <div class="item">
                <div class="left-lable">性别：</div>
                <div class="right-lable">男</div>
              </div>
              <div class="item">
                <div class="left-lable">证件号码：</div>
                <div class="right-lable">未知</div>
              </div>
              <div class="item">
                <div class="left-lable">手机号码：</div>
                <div class="right-lable">未知</div>
              </div>
              <div class="item">
                <div class="left-lable">户籍地址：</div>
                <div class="right-lable">未知</div>
              </div>
              <div class="item">
                <div class="left-lable">告警事件：</div>
                <div class="right-lable">黑名单人员告警</div>
              </div>
              <div class="item">
                <div class="left-lable">备注信息：</div>
                <div class="right-lable">乱砍乱伐，破坏森林资源</div>
              </div>
              <div class="item">
                <div class="left-lable">抓拍时间：</div>
                <div class="right-lable">2021/01/22 16:55:26</div>
              </div>
              <div class="item">
                <div class="left-lable">抓拍地点：</div>
                <div class="right-lable">幸福佳苑test001</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 告警审核 -->
        <div class="alarm-audit">
          <div class="topTitle">
            <div class="line"></div>
            <div class="txt">告警审核</div>
          </div>
          <div class="bottomCon">
            <div class="item">
              <div class="left-lable">审核结果:</div>
              <div class="right-lable">
                <el-radio v-model="radio" label="1">误报</el-radio>
                <el-radio v-model="radio" label="2">确定</el-radio>
              </div>
            </div>
            <div class="item">
              <div class="left-lable remark">备注 :</div>
              <div class="right-lable textarea">
                <textarea type="text" placeholder="请输入"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dcBottom">
        <p class="item" v-on:click="handleHintInfo">确定</p>
        <p class="item" v-on:click="handleCloseLayer">取消</p>
      </div>
    </div>
  </div>
</template> 
<script>
export default {
  props: {
    showTaskListLayer: {
      type: Boolean,
      default: false,
    },
  },

  name: "",
  data() {
    return {
      radio: "1",
    };
  },
  created() {},
  computed: {},
  methods: {
    handleHintInfo() {},
    handleCloseLayer() {
      this.$emit("update:showTaskListLayer", false);
    },
  },
};
</script>
<style lang='scss'  scoped>
@import "@/scss/TaskListLayer/taskListLayer.scss";
</style>
