<template>
  <div class="maskLayer" v-if="showTaskListLayerType2">
    <div class="app-message dialogCon">
      <div class="dcTitle">
        <p class="title">抓拍告警</p>
        <div class="dcRight">
          <p class="touping">
            <img src="@/assets/images/listMessage/button_touping.png" />
          </p>
          <p class="close" v-on:click="handleCloseLayer">
            <img src="@/assets/images/listMessage/button_close.png" />
          </p>
        </div>
      </div>
      <div class="hlCon">
        <div class="photo-info">
          <div class="leftCon">
            <div class="topCon">
              <!-- <VideoList :streamInfo="deviceInfo"></VideoList> -->
              <assemblyFlv :streamInfo="deviceInfo"></assemblyFlv>
            </div>
            <div class="bottomCon">
              <div class="userPic">
                <!-- 请求有图片就覆盖下面的图片 -->
                <viewer :images="deviceInfo.pics">
                  <div
                    class="img"
                    v-for="(item, index) in deviceInfo.pics"
                    :key="index"
                    :onerror="errorImg"
                  >
                    <img :src="item" alt="" />
                  </div>
                </viewer>
              </div>
              <div class="comparePic">
                <!-- 默认图片写死的 -->
                <div class="img">
                  <img
                    src="@/assets/images/listMessage/zhuapai_no picture.png"
                    alt=""
                  />
                </div>
                <div class="img">
                  <img
                    src="@/assets/images/listMessage/zhuapai_no picture.png"
                    alt=""
                  />
                </div>
                <div class="img">
                  <img
                    src="@/assets/images/listMessage/zhuapai_no picture.png"
                    alt=""
                  />
                </div>
                <div class="img">
                  <img
                    src="@/assets/images/listMessage/zhuapai_no picture.png"
                    alt=""
                  />
                </div>
                <div class="img">
                  <img
                    src="@/assets/images/listMessage/zhuapai_no picture.png"
                    alt=""
                  />
                </div>
                <div class="img">
                  <img
                    src="@/assets/images/listMessage/zhuapai_no picture.png"
                    alt=""
                  />
                </div>
                <div class="img">
                  <img
                    src="@/assets/images/listMessage/zhuapai_no picture.png"
                    alt=""
                  />
                </div>
                <div class="img">
                  <img
                    src="@/assets/images/listMessage/zhuapai_no picture.png"
                    alt=""
                  />
                </div>
                <div class="img">
                  <img
                    src="@/assets/images/listMessage/zhuapai_no picture.png"
                    alt=""
                  />
                </div>
                <div class="img">
                  <img
                    src="@/assets/images/listMessage/zhuapai_no picture.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="rightCon">
            <div class="topCon">
              <div class="arbot_img">
                <img src="@/assets/images/listMessage/arbot_img.png" alt="" />
                <p>1号哨兵判断结果</p>
              </div>
              <div class="warn" v-if="deviceInfo.alarm_level == 1">
                <img
                  src="@/assets/images/listMessage/yanzhong_img.png"
                  alt=""
                />
              </div>
              <div class="warn" v-else-if="deviceInfo.alarm_level == 2">
                <img src="@/assets/images/listMessage/ciji_img.png" alt="" />
              </div>
              <div class="warn" v-else-if="deviceInfo.alarm_level == 3">
                <img src="@/assets/images/listMessage/yibani_img.png" alt="" />
              </div>
            </div>
            <div class="bottomCon">
              <div class="item">
                <div class="left-lable">抓拍地点：</div>
                <div class="right-lable">{{ deviceInfo.address }}</div>
              </div>
              <div class="item">
                <div class="left-lable">抓拍时间：</div>
                <div class="right-lable">{{ deviceInfo.create_time }}</div>
              </div>
              <div class="item">
                <div class="left-lable">报警类型：</div>
                <div class="right-lable">{{ deviceInfo.alarm_event }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 告警审核 -->
        <div class="alarm-audit">
          <div class="topTitle">
            <div class="line"></div>
            <div class="txt">告警审核</div>
          </div>
          <div class="bottomCon">
            <div class="item">
              <div class="left-lable">审核结果:</div>
              <div class="right-lable">
                <el-radio v-model="radio" label="1">误报</el-radio>
                <el-radio v-model="radio" label="2">确定</el-radio>
              </div>
            </div>
            <div class="item">
              <div class="left-lable remark">备注 :</div>
              <div class="right-lable textarea">
                <textarea
                  type="text"
                  placeholder="请输入"
                  v-model="parmasInfo.remark"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dcBottom">
        <p class="item" v-on:click="handleHintInfo">确定</p>
        <p class="item" v-on:click="handleCloseLayer">取消</p>
      </div>
    </div>
    <!-- 通知类型选择 -->
    <SelectType
      v-if="showSelectTypeLayer"
      :showSelectTypeLayer.sync="showSelectTypeLayer"
      :parmasInfo="parmasInfo"
      @showFlag="showFlag"
    ></SelectType>
  </div>
</template> 
<script>
// import VideoList from "@/components/videoList/videoList.vue";
import assemblyFlv from "../videoList/assembly-flv-layer.vue";
import SelectType from "./details/selectType.vue";
export default {
  props: {
    showTaskListLayerType2: {
      type: Boolean,
      default: false,
    },
    deviceInfo: {
      type: Object,
      default: () => {},
    },
  },
  components: { assemblyFlv, SelectType },
  name: "",
  data() {
    return {
      showSelectTypeLayer: false,
      videoUrl: "",
      radio: "1",
      parmasInfo: {
        id: this.deviceInfo.id,
        false_alarm: "",
        type: null,
        remark: "",
      },
    };
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {
    async handleHintInfo() {
      this.parmasInfo.false_alarm = Number(this.radio);
      this.showSelectTypeLayer = true;
    },

    showFlag(val) {
      console.log(val);
      if (!val) {
        this.$emit("getTableData");
        this.$emit("update:showTaskListLayerType2", false);
      }
    },

    handleCloseLayer() {
      this.$emit("update:showTaskListLayerType2", false);
    },
  },
};
</script>
<style lang='scss'  scoped>
@import "@/scss/TaskListLayer/taskListLayerType2.scss";
</style>
