<template>
  <my-farme :width="1920" :height="1080" bgColor="#eee">
    <div class="appPage">
      <div class="header">
        <HeaderInfo />
      </div>
      <div class="content">
        <taskList />
      </div>
      <div class="footer">版权所有：湖北三三建维科技有限公司</div>
    </div>
  </my-farme>
</template>

<script>
import HeaderInfo from "./components/HeaderInfo.vue";
import taskList from "../views/taskList/taskList.vue";

export default {
  name: "Layout",
  components: {
    HeaderInfo,
    taskList,
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang='scss'  scoped>
.appPage {
  position: relative;
  width: 100%;
  height: 100%;
  background: url(../assets/images/index/bg_img1.png) no-repeat;
  background-size: auto;
  .header {
    color:#fff;
    width: 100%;
    height: 61px;
  }
  .content {
    width: 100%;
    // height: 1014px;
    padding: 19px 30px;
  }
  .footer {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%,0);
    width: 100%;
    height: 15px;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
  }
}
</style>
