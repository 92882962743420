<template>
  <div class="container">
    <div class="topTitle">
      <div class="leftCon">突发任务列表</div>
      <div class="rightCon">
        <img src="@/assets/images/index/button_quanping.png" alt="" />
      </div>
    </div>
    <div class="content">
      <div class="conLeft">
        <div class="videoList">
          <template v-if="emergencyList && emergencyList.length > 0">
            <div
              class="warning item"
              v-for="(item,indedx) in emergencyList"
              :key="indedx"
            >
              <!-- <VideoList class="video" :streamInfo="item"></VideoList> -->
              <!-- <VideoList class="video"></VideoList> -->
               <assemblyFlv class="video" :streamInfo="item"></assemblyFlv>
              <div class="mask" @click="taskListHandle(item)">
                <img
                  class="mask-img"
                  v-if="item.alarm_level == 0"
                  src=""
                  alt=""
                />
                <img
                  class="mask-img"
                  v-else-if="item.alarm_level == 1"
                  src="@/assets/images/index/yanzhong_kuang.png"
                  alt=""
                />
                <img
                  class="mask-img"
                  v-else-if="item.alarm_level == 2"
                  src="@/assets/images/index/ciji_kuang.png"
                  alt=""
                />
                <img
                  class="mask-img"
                  v-else-if="item.alarm_level == 3"
                  src="@/assets/images/index/yiban_kuang.png"
                  alt=""
                />
                <div class="maskCon">
                  <div class="topCon">
                    <!-- <div class="leftTime">{{ item.create_time }}</div> -->
                    <div class="leftTime"></div>
                    <div class="rightCon" v-if="item.alarm_level == 0">
                      <img src="" alt="" />
                      <span style="color: #49ce39">正常</span>
                    </div>
                    <div class="rightCon" v-else-if="item.alarm_level == 1">
                      <img
                        src="@/assets/images/index/alarm_yanzhong.png"
                        alt=""
                      />
                      <span style="color: #f7517f">严重告警 </span>
                    </div>
                    <div class="rightCon" v-else-if="item.alarm_level == 2">
                      <img src="@/assets/images/index/alarm_ciji.png" alt="" />
                      <span style="color: #fe9239">次级告警</span>
                    </div>
                    <div class="rightCon" v-else-if="item.alarm_level == 3">
                      <img src="@/assets/images/index/alarm_yiban.png" alt="" />
                      <span style="color: #feeb1a">一般告警</span>
                    </div>
                  </div>
                  <div class="infoC">
                    <p class="infoC-title">{{ item.community_name }}</p>
                    <p>{{ item.address }}</p>
                    <p>
                      设备位置：<span>{{ item.address }}</span>
                    </p>
                    <p>
                      告警事件：<span>{{ item.alarm_event }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- <template v-else>
            <div class="bg-novideo" v-for="(item,index) in 9" :key="index">
                <img src="@/assets/images/index/no_video.png" alt="">
              </div>
          </template> -->
          <!-- <div class="serious-warning item" @click="taskListType2Handle"></div>
          <div class="secondary-warning item" @click="taskListType3Handle"></div> -->
        </div>
      </div>
      <div class="conRight">
        <div class="task">
          <div class="top-title">
            <p class="txt">告警任务</p>
            <div class="line"></div>
          </div>
          <div class="content_info">
            <div
              class="content_info_list item"
              v-for="(item, index) in emergencyList"
              :key="index"
              @click="checkItemHandle(item, index)"
            >
              <div class="task-info-left">
                <span>{{ item.alarm_event }}</span>
              </div>
              <div :class="activeIndex == index ? 'active' : ''"></div>
              <div class="task-info-right">
                <img
                  v-if="item.alarm_level == 0"
                  src="@/assets/images/index/alarm_zhengchang.png"
                  alt=""
                />
                <img
                  v-else-if="item.alarm_level == 1"
                  src="@/assets/images/index/alarm_yanzhong.png"
                  alt=""
                />
                <img
                  v-else-if="item.alarm_level == 2"
                  src="@/assets/images/index/alarm_ciji.png"
                  alt=""
                />
                <img
                  v-else-if="item.alarm_level == 3"
                  src="@/assets/images/index/alarm_yiban.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="details" v-show="flag">
          <div class="top-title">
            <p class="txt">告警详情</p>
            <div class="line"></div>
          </div>
          <div class="content_info">
            <div class="content_info_list">
              <div><span>小区名称：</span> {{ checkItem.community_name }}</div>
              <div><span>告警设备：</span> {{ checkItem.device_name }}</div>
              <div><span>设备位置：</span> {{ checkItem.address }}</div>
              <div><span>告警事件：</span> {{ checkItem.alarm_event }}</div>
              <div><span>告警时间：</span> {{ checkItem.create_time }}</div>
            </div>
          </div>
        </div>
        <div class="handle" v-show="flag">
          <div class="top-title">
            <p class="txt">处理方式</p>
            <div class="line"></div>
          </div>
          <div class="content_info">
            <button class="app" @click="messageHandle('1')">APP通知</button>
            <button @click="messageHandle('2')">电话通知</button>
            <button @click="messageHandle('3')">公网处理</button>
            <button @click="messageHandle('4')">运维派单</button>
          </div>
        </div>
        <div class="submit" v-show="flag">
          <button @click="submit">提交</button>
        </div>
      </div>
    </div>
    <!-- 处理方式消息弹窗 -->
    <appMessageLayer
      :showAppMessageLayer.sync="showAppMessageLayer"
      :checkItem="checkItem"
      :subType="subType"
      @getTableData="publicGetTableData"
    ></appMessageLayer>
    <phoneMessageLayer
      :showPhoneMessageLayer.sync="showPhoneMessageLayer"
      :checkItem="checkItem"
      :subType="subType"
    ></phoneMessageLayer>
    <publicSpeakerLayer
      :showPublicSpeakerLayer.sync="showPublicSpeakerLayer"
      :checkItem="checkItem"
      :subType="subType"
    ></publicSpeakerLayer>
    <!-- 突发任务列表弹窗 -->
    <taskListLayer
      :showTaskListLayer.sync="showTaskListLayer"
      @getTableData="publicGetTableData"
    ></taskListLayer>
    <!-- 电梯告警 -->
    <taskListLayerType2
      v-if="showTaskListLayerType2"
      :showTaskListLayerType2.sync="showTaskListLayerType2"
      :deviceInfo="deviceInfo"
      @getTableData="publicGetTableData"
    ></taskListLayerType2>
    <taskListLayerType3
      :showTaskListLayerType3.sync="showTaskListLayerType3"
      @getTableData="publicGetTableData"
    ></taskListLayerType3>
    <!-- 批量提交 -->
    <submitInfoLayer
      v-if="showSubmitInfoLayer"
      :showSubmitInfoLayer.sync="showSubmitInfoLayer"
      :emergencyList="emergencyList"
      @getTableData="publicGetTableData"
    ></submitInfoLayer>
  </div>
</template>
<script>
import appMessageLayer from "../../components/MessageLayer/appMessageLayer.vue";
import phoneMessageLayer from "../../components/MessageLayer/phoneMessageLayer.vue";
import publicSpeakerLayer from "../../components/MessageLayer/publicSpeakerLayer.vue";
import taskListLayer from "../../components/TaskListLayer/taskListLayer.vue";
import taskListLayerType2 from "../../components/TaskListLayer/taskListLayerType2.vue";
import taskListLayerType3 from "../../components/TaskListLayer/taskListLayerType3.vue";
import submitInfoLayer from "../../components/submit/submitInfoLayer.vue";

// import VideoList from "@/components/videoList/videoList.vue";
import assemblyFlv from "../../components/videoList/assembly-flv.vue";
import taskListHttps from "../../https/taskList/index";
export default {
  name: "",
  components: {
    appMessageLayer,
    phoneMessageLayer,
    publicSpeakerLayer,
    taskListLayer,
    taskListLayerType2,
    taskListLayerType3,
    submitInfoLayer,
   assemblyFlv
  },
  data() {
    return {
      flag: false,
      videoUrl: "",
      showAppMessageLayer: false,
      showPhoneMessageLayer: false,
      showPublicSpeakerLayer: false,
      showTaskListLayer: false,
      showTaskListLayerType2: false,
      showTaskListLayerType3: false,
      showSubmitInfoLayer: false,
      showVideoDialog: true,
      formInline: {
        offset: 1,
        limit: 10,
      },
      activeIndex: null,
      emergencyList: [],
      checkItem: {},
      deviceInfo: {},
      subType: "",
      websock: null, // ws实例
      wsUrl: "ws://192.168.234.131:9501", // ws地址
    };
  },
  created() {
    this.getVideoList();
    this.emergencyList = [
    //  {
    //    alarm_level:1
    //  }
    ];
  },
  mounted() {
    // this.initWebSocket()
  },
  destroyed() {
    // 离开路由之后断开websocket连接
    // this.websock.close();
  },
  computed: {},
  methods: {
    // 初始化weosocket
    initWebSocket() {
      if (typeof WebSocket === "undefined")
        return console.log("您的浏览器不支持websocket");
      this.websock = new WebSocket(this.wsUrl);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      // 连接建立之后执行send方法发送数据
      this.websock.send("getEmergencyList");
    },
    websocketonerror() {
      // 连接建立失败重连
      this.initWebSocket();
    },
    websocketonmessage(e) {
      // 数据接收
      const redata = JSON.parse(e.data);
      console.log("接收的数据", redata);
      this.emergencyList = redata;
    },
    websocketclose(e) {
      // 关闭
      console.log("断开连接", e);
    },

    // 获取列表
    getVideoList() {
      let that = this;
      taskListHttps.getTableList(that.formInline).then(function (res) {
        if (res.data.code == 200) {
          that.emergencyList = res.data.data;
        } else {
          that.$message({
            showClose: true,
            message: "暂无数据",
            type: "error",
          });
        }
      });
    },
    publicGetTableData() {
      this.getVideoList();
    },

    //选择告警事件
    checkItemHandle(item, index) {
      // console.log(item, 66);
      this.activeIndex = index;
      this.isActive = true;
      this.checkItem = item;
      this.flag = true;
    },
    messageHandle(val) {
      console.log(val, "val");
      // console.log(this.checkItem);
      if (this.checkItem.id) {
        if (val == 1) {
          this.subType = val;
          this.showAppMessageLayer = true;
        } else if (val == 2) {
          this.subType = val;
          this.showPhoneMessageLayer = true;
        } else if (val == 3) {
          this.subType = val;
          this.showPublicSpeakerLayer = true;
        } else {
          this.subType = val;
          console.log("运维派单");
        }
      } else {
        this.$message.warning("请先选择告警任务");
      }
    },
    taskListHandle(item) {
      if (item.event_level_no == 1) {
        this.deviceInfo = item;
        this.showTaskListLayerType2 = true;
      } else if (item.event_level_no == 2) {
        this.deviceInfo = item;
        this.showTaskListLayer = true;
      } else if (item.event_level_no == 3) {
        this.deviceInfo = item;
        this.showTaskListLayerType3 = true;
      }
    },
    submit() {
      this.showSubmitInfoLayer = true;
    },
  },
};
</script>
<style lang='scss'  scoped>
@import "@/scss/taskList/index.scss";
</style>
