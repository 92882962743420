<template>
  <div class="maskLayer" v-if="showSelectTypeLayer">
    <div class="app-message dialogCon">
      <div class="dcTitle">
        <p class="title">处理方式</p>
        <p class="close" v-on:click="handleCloseLayer">
          <img src="@/assets/images/message/tankuang_delete.png" />
        </p>
      </div>
      <div class="hlCon">
        <div class="handle">
          <div class="topTitle">
            <div class="line"></div>
            <p class="txt">选择处理方式</p>
          </div>
          <div class="content_info">
            <button
              :class="activeIndex == 1 ? 'active' : ''"
              @click="checkItemHandle(1)"
            >
              APP通知
            </button>
            <button
              :class="activeIndex == 2 ? 'active' : ''"
              @click="checkItemHandle(2)"
            >
              电话通知
            </button>
            <button
              :class="activeIndex == 3 ? 'active' : ''"
              @click="checkItemHandle(3)"
            >
              公网处理
            </button>
            <button
              :class="activeIndex == 4 ? 'active' : ''"
              @click="checkItemHandle(4)"
            >
              运维派单
            </button>
          </div>
        </div>
      </div>
      <div class="dcBottom">
        <p class="item" v-on:click="handleHintInfo">确定</p>
        <p class="item" v-on:click="handleCloseLayer">取消</p>
      </div>
    </div>
  </div>
</template> 
<script>
import taskHttps from "@/https/taskList/index";
export default {
  props: {
    showSelectTypeLayer: {
      type: Boolean,
      default: false,
    },
    parmasInfo: {
      type: Object,
      default: () => {},
    },
  },
  name: "",
  data() {
    return {
      activeIndex: null,
      selectType: null,  //处理方式
    };
  },
  created() {},
  computed: {},
  methods: {
    //选择告警事件
    checkItemHandle(index) {
      this.selectType = index;  //处理方式
      this.activeIndex = index;
    },
    handleHintInfo() {
      //处理方式
      if (this.selectType == null) {
        this.$message.error("选择处理方式");
      } else {
        this.parmasInfo.type = this.selectType;
        taskHttps.chargeAlarm(this.parmasInfo).then((res) => {
          if (res.data.code == 200) {
            this.$message.success("提交成功");
            this.$emit("update:showSelectTypeLayer", false);
            this.$emit("showFlag", false);
          } else {
            this.$message.error("提交失败");
          }
        });
      }
    },
    handleCloseLayer() {
      this.$emit("update:showSelectTypeLayer", false);
    },
  },
};
</script>
<style lang='scss'  scoped>
.maskLayer {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0rem;
  top: 0rem;
  z-index: 999;

  .dialogCon {
    width: 600px;
    // height: 530px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding-bottom: 80px;
    border-radius: 6px 6px 0px 0px;

    .dcTitle {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 36px;
      background: #2c807f;
      box-shadow: 0px 4px 6px 0px rgba(2, 30, 30, 0.4);
      border-radius: 6px 6px 0px 0px;

      .title {
        height: 16px;
        font-size: 16px;
        color: #ffffff;
        line-height: 16px;
        margin-left: 15px;
        margin-top: 10px;
      }

      .close {
        margin-right: 8px;
        margin-top: 8px;
        cursor: pointer;
      }
    }

    .hlCon {
      width: 100%;
      height: 250px;
      background: #133435;
      box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.3);
      padding-top: 16px;
      padding-bottom: 60px;
      .handle {
        .topTitle {
          height: 20px;
          line-height: 20px;
          display: flex;
          justify-content: start;

          .line {
            width: 3px;
            height: 10px;
            background: #00ffff;
            margin-top: 4px;
            margin-left: 20px;
            margin-right: 4px;
          }

          .txt {
            height: 17px;
            font-size: 16px;
            color: #00ffff;
            line-height: 17px;
          }
        }
        .content_info {
          padding-left: 60px;
          padding-top: 80px;

          button {
            width: 100px;
            height: 30px;
            font-size: 14px;
            color: #91a4b7;
            background-color: transparent;
            line-height: 30px;
            border: 1px solid #91a4b7;
            border-radius: 2px;
            margin-right: 20px;
            margin-bottom: 10px;
            cursor: pointer;
          }
          & .active {
            color: #ffffff;
            background: #6fcf97;
          }
        }
      }
    }

    .dcBottom {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 80px;
      background: #193f40;
      margin-bottom: 0;

      .item {
        width: 80px;
        height: 30px;
        color: #fff;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        background: #1faba3;
        cursor: pointer;

        &:nth-child(1) {
          margin-right: 10px;
        }

        &:nth-child(2) {
          margin-right: 20px;
          background: #0f5551;
        }
      }
    }
  }
}
</style>
