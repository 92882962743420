<template>
  <div class="maskLayer" v-if="showPublicSpeakerLayer">
    <div class="app-message dialogCon">
      <div class="dcTitle">
        <p class="title">公网对讲</p>
        <p class="close" v-on:click="handleCloseLayer">
          <img src="../../assets/images/message/tankuang_delete.png" />
        </p>
      </div>
      <div class="hlCon">
        <!-- 设备信息 -->
        <div class="device-info">
          <div class="topTitle">
            <div class="line"></div>
            <div class="txt">设备信息</div>
          </div>
          <div class="bottomCon">
            <div class="item">
              <div class="left-lable">社区名称 :</div>
              <div class="right-lable">{{ checkItem.community_name }}</div>
            </div>
            <div class="item">
              <div class="left-lable">设备名称 :</div>
              <div class="right-lable">
                {{ checkItem.community_name }}{{ checkItem.device_name }}设备
              </div>
            </div>
            <div class="item">
              <div class="left-lable">设备位置 :</div>
              <div class="right-lable">{{ checkItem.address }}</div>
            </div>
            <div class="item">
              <div class="left-lable">告警事件 :</div>
              <div class="right-lable">{{ checkItem.alarm_event }}</div>
            </div>
            <div class="item">
              <div class="left-lable">告警等级 :</div>
              <div class="right-lable" v-if="checkItem.alarm_level == 0">
                <img src="@/assets/images/index/alarm_zhengchang.png" alt="" />
                正常
              </div>
              <div class="right-lable" v-else-if="checkItem.alarm_level == 1">
                <img src="@/assets/images/index/alarm_yanzhong.png" alt="" />
                严重告警
              </div>
              <div class="right-lable" v-else-if="checkItem.alarm_level == 2">
                <img src="@/assets/images/index/alarm_ciji.png" alt="" />
                次级告警
              </div>
              <div class="right-lable" v-else-if="checkItem.alarm_level == 3">
                <img src="@/assets/images/index/alarm_yiban.png" alt="" />
                一般告警
              </div>
            </div>
            <div class="item">
              <div class="left-lable">告警时间 :</div>
              <div class="right-lable">2022-06-12 12:00:00</div>
            </div>
            <div class="item">
              <div class="left-lable">值班人员 :</div>
              <div class="right-lable">2号哨兵 张志平</div>
            </div>
            <div class="item">
              <div class="left-lable">对讲频道 :</div>
              <div class="right-lable">
                <div class="btn">
                  <button @click="showTaskBackLayer=true">1号频道</button>
                  <button>2号频道</button>
                  <button>3号频道</button>
                  <button>4号频道</button>
                  <button>5号频道</button>
                  <button>6号频道</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dcBottom">
        <p class="item" v-on:click="handleHintInfo">确定</p>
        <p class="item" v-on:click="handleCloseLayer">取消</p>
      </div>
    </div>
    <!-- 公网对讲 -->
    <TaskBack :showTaskBackLayer.sync="showTaskBackLayer"></TaskBack>
  </div>
</template> 
<script>
import TaskBack from "./taskDetails/talkback.vue";
export default {
  components: { TaskBack },
  props: {
    showPublicSpeakerLayer: {
      type: Boolean,
      default: false,
    },
    checkItem: {
      type: Object,
      default: () => {},
    },
    subType: {
      type: String,
      default: "",
    },
  },
  name: "",
  data() {
    return {
      radio: "1",
      showTaskBackLayer:false
    };
  },
  created() {},
  computed: {},
  methods: {
    handleHintInfo() {},
    handleCloseLayer() {
      this.$emit("update:showPublicSpeakerLayer", false);
    },
  },
};
</script>
<style lang='scss'  scoped>
@import "@/scss/MessageLayer/publicSpeakerLayer.scss";
</style>
