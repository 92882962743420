import { render, staticRenderFns } from "./assembly-flv-layer.vue?vue&type=template&id=2afb3293&"
import script from "./assembly-flv-layer.vue?vue&type=script&lang=js&"
export * from "./assembly-flv-layer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports