<template>
  <div class="maskLayer" v-if="showTaskListLayerType3">
    <div class="app-message dialogCon">
      <div class="dcTitle">
        <p class="title">抓拍告警</p>
        <div class="dcRight">
          <p class="touping">
            <img src="@/assets/images/listMessage/button_touping.png" />
          </p>
          <p class="close" v-on:click="handleCloseLayer">
            <img src="@/assets/images/listMessage/button_close.png" />
          </p>
        </div>
      </div>
      <div class="hlCon">
        <div class="photo-info">
         <img src="@/assets/images/listMessage/tankuang_no_video2.png" alt="">
        </div>
        <!-- 告警审核 -->
        <div class="alarm-audit">
          <div class="topTitle">
            <div class="line"></div>
            <div class="txt">告警审核</div>
          </div>
          <div class="bottomCon">
            <div class="item">
              <div class="left-lable">审核结果:</div>
              <div class="right-lable">
                <el-radio v-model="radio" label="1">误报</el-radio>
                <el-radio v-model="radio" label="2">确定</el-radio>
              </div>
            </div>
            <div class="item">
              <div class="left-lable remark">备注 :</div>
              <div class="right-lable textarea">
                <textarea type="text" placeholder="请输入"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dcBottom">
        <p class="item" v-on:click="handleHintInfo">确定</p>
        <p class="item" v-on:click="handleCloseLayer">取消</p>
      </div>
    </div>
  </div>
</template> 
<script>
export default {
  props: {
    showTaskListLayerType3: {
      type: Boolean,
      default: false,
    },
  },

  name: "",
  data() {
    return {
      radio: "1",
    };
  },
  created() {},
  computed: {},
  methods: {
    handleHintInfo() {},
    handleCloseLayer() {
      this.$emit("update:showTaskListLayerType3", false);
    },
  },
};
</script>
<style lang='scss'  scoped>
@import "@/scss/TaskListLayer/taskListLayerType3.scss";
</style>
