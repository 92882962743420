import request from '@/utils/request'

function getTableList(data){	//突发任务列表
 return request({
    url: '/api/front/emergency_list',
    method: 'post',
    data: data 
 })
}
function chargeAlarm(data){	//突发任务--告警审核
 return request({
    url: '/api/front/charge_alarm',
    method: 'post',
    data: data 
 })
}
function submit(data){	//突发任务--处理方式提交
 return request({
    url: '/api/front/submit_alarm_notify',
    method: 'post',
    data: data 
 })
}
function submitAll(data){	//突发任务--批量提交
 return request({
    url: '/api/front/batch_submit_alarm_notify',
    method: 'post',
    data: data ,
 })
}


export default {
	getTableList,
   chargeAlarm,
   submit,
   submitAll
}