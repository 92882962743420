
<template>
  <div class="mainContainer" style="width:100%;height:100%">
    <video :id="'videoElementLayer'+streamInfo.id" controls autoplay style="width:100%;height:100%"></video>
  </div>
</template>

<script>
import flvJs from "flv.js";
export default {
    props: {
    streamInfo: {
      type: Object,
      default: () => {},
    },
  },
  name: "",

  data() {
    return {
      flvPlayer: null, // flv实例
      pauseOrPlay: false,
      url: this.streamInfo.stream_url,
    };
  },
  mounted() {
    this.flvInit();
  },
  methods: {
    flvInit() {
      let videoPlayer = document.getElementById("videoElementLayer"+this.streamInfo.id); //获取html
      if (flvJs.isSupported()) {
        //创建flv实例
        this.flvPlayer = flvJs.createPlayer(
          {
            //MediaDataSource
            type: "flv",
            hasAudio: false, //是否带音频播放
            isLive: true, //<====加个这个
            url: this.url,
          },
          {
            //Config
            enableWorker: false,
            enableStashBuffer: false,
            //当带音频播放时,config部分配置项尽量采取默认状态,否则过分优化会造成卡死
          }
        );
        this.flvPlayer.attachMediaElement(videoPlayer);
        this.flvPlayer.load(); //加载
        this.flvPlayer.play(); //播放
      }
    },
  },
};
</script>


